import styled from "@emotion/styled";
import type { BundleEntry, DiagnosticReport } from "fhir/r4";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ArrowLeftIcon } from "src/constants/icons";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import { dateLocalString } from "src/lib/date";
import type { ObservationBundleSearchParams } from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import ObservationBundleBloc from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker, useBloc } from "src/state/state";
import goldenHourConfidence from "src/ui/assets/patterns/goldenHourConfidence.jpg";
import goldenHourRefresh from "src/ui/assets/patterns/goldenHourRefresh.jpg";
import goldenHourSupport from "src/ui/assets/patterns/goldenHourSupport.jpg";
import midMorningConfidence from "src/ui/assets/patterns/midMorningConfidence.jpg";
import midMorningRefresh from "src/ui/assets/patterns/midMorningRefresh.jpg";
import midMorningSupport from "src/ui/assets/patterns/midMorningSupport.jpg";
import { LABS_BASE_PAGE_URL } from "src/ui/components/LabResultsDataSubPageRouter/LabResultsDataSubPageRouter";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import Translate from "src/ui/components/Translate/Translate";
import LabResultRow from "src/ui/components/UserLabResults/LabResultRow";

//#region Styled components
const LabResultsWrapper = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: var(--light-shadow);
  overflow: hidden;
`;

const LabResultsHero = styled.div<{
  backgroundImage: string | null | undefined;
}>`
  padding: 1.5rem;
  padding-top: calc(1.5rem + var(--ion-safe-area-top, 0px));
  border-radius: 0;
  margin: -1rem -1.6rem 1.5rem;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  ${({ backgroundImage }): string =>
    backgroundImage ? `background-image: url(${backgroundImage});` : ""}

  @media screen and (min-width: ${APP_CONTENT_WIDTH}px) {
    padding: 2rem;
    border-radius: 0.5rem;
    margin: 0 0 1.5rem;
  }
`;

const LabResultsTitle = styled.h5`
  margin-bottom: 1rem !important;

  @media screen and (min-width: 768px) {
    margin-bottom: 1.5rem !important;
  }
`;

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const DateRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  > small {
    min-width: 4.5rem;
  }
`;

const DateValue = styled.span`
  border-radius: 0.125rem;
  background: var(--Secondary-Dark-Cream, #f2efe7);
  padding: 0.125rem;
  font-family:
    "Monaco",
    "STK Bureau",
    "Open Sans",
    -apple-system,
    Roboto,
    Arial,
    sans-serif;
  font-size: 0.75rem;
`;

const IconWrapperButton = styled(Link)`
  background-color: rgba(33, 33, 33, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: fit-content;
  padding: 0.125rem;
  margin-bottom: 1.5rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
//#endregion

// Background image
const backgroundImagesMap = new Map<string | null | undefined, string>([
  ["midMorningSupport", midMorningSupport],
  ["midMorningRefresh", midMorningRefresh],
  ["midMorningConfidence", midMorningConfidence],
  ["goldenHourSupport", goldenHourSupport],
  ["goldenHourRefresh", goldenHourRefresh],
  ["goldenHourConfidence", goldenHourConfidence]
]);

// Helper for date rendering
const renderDate = (date?: string): string => {
  return dateLocalString(date, {
    dateStyle: "short",
    timeStyle: "auto",
    hourCycle: "h12"
  });
};

const ListLabsFromBundle: FC = () => {
  const [
    { observationBundle, observations },
    {
      loadObservationBundle,
      getObservationBundleTitle,
      getObservationsFromBundle
    }
  ] = useBloc(ObservationBundleBloc, {
    create: () => new ObservationBundleBloc()
  });

  const [searchParams] = useSearchParams();
  const searchParamsObj: ObservationBundleSearchParams = Object.fromEntries([
    ...searchParams
  ]);

  useEffect(() => {
    void loadObservationBundle(searchParamsObj);

    tracker.track(TrackEvent.LabReportOpened, {
      data: {
        Date: searchParamsObj.observationDate,
        Category: searchParamsObj.category,
        Source: searchParamsObj.source,
        "Lab Order ID": searchParamsObj.labOrderId
      }
    });
  }, []);

  useEffect(() => {
    getObservationsFromBundle(observationBundle);
  }, [observationBundle]);

  const diagnosticReport = useMemo(() => {
    return observationBundle?.entry?.find(
      (entry) => entry.resource?.resourceType === "DiagnosticReport"
    ) as BundleEntry<DiagnosticReport> | undefined;
  }, [observationBundle]);

  const observationBundleTitle = useMemo(() => {
    const title = diagnosticReport?.resource?.performer?.[0].identifier?.value;

    return getObservationBundleTitle(title);
  }, [diagnosticReport]);

  const collectedAtDate = useMemo(() => {
    return renderDate(diagnosticReport?.resource?.effectiveDateTime);
  }, [diagnosticReport]);

  const receivedAtDate = useMemo(() => {
    return renderDate(observationBundle?.timestamp);
  }, [observationBundle]);

  const backgroundImage = useMemo(() => {
    if (!observationBundle) {
      return undefined;
    }

    const randomImageIdentifier =
      new Date(observationBundle.timestamp ?? "").getDate() +
      new Date(observationBundle.timestamp ?? "").getHours();
    const backgroundImagesMapKeys = [...backgroundImagesMap.keys()];
    const index = randomImageIdentifier % (backgroundImagesMapKeys.length - 1);
    const randomImageKey = backgroundImagesMapKeys[index];

    return backgroundImagesMap.get(randomImageKey);
  }, [observationBundle]);

  return (
    <>
      <LabResultsHero backgroundImage={backgroundImage}>
        <div>
          <IconWrapperButton to={LABS_BASE_PAGE_URL}>
            <ArrowLeftIcon />
          </IconWrapperButton>

          <LabResultsTitle className="m0 strong">
            {observationBundleTitle}
          </LabResultsTitle>

          <DatesWrapper>
            {collectedAtDate && (
              <DateRow>
                <small className="m0">
                  <Translate msg="collected" />
                </small>
                <DateValue>{collectedAtDate}</DateValue>
              </DateRow>
            )}
            {receivedAtDate && (
              <DateRow>
                <small className="m0">
                  <Translate msg="received" />
                </small>
                <DateValue>{receivedAtDate}</DateValue>
              </DateRow>
            )}
          </DatesWrapper>
        </div>
      </LabResultsHero>

      <CollectionTitle>
        <Translate msg="results" />
      </CollectionTitle>

      <LabResultsWrapper>
        {observations?.map((obs) => (
          <LabResultRow key={obs.resource?.id} observation={obs.resource} />
        ))}
      </LabResultsWrapper>
    </>
  );
};

export default ListLabsFromBundle;
