import styled from "@emotion/styled";
import type { Observation } from "fhir/r4";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { IconLabValueCursor } from "src/constants/icons";
import ObservationBundleBloc from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import { useBloc } from "src/state/state";

//#region Styled components
const Range = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Segments = styled.div`
  display: flex;
  gap: 0.25rem;
  position: relative;
`;

const Segment = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: var(--width, 0%);
  min-width: 15%;

  @media screen and (min-width: 768px) {
    min-width: 10%;
  }

  &[data-onlyonelimit="true"] {
    max-width: 50%;
  }
`;

const SegmentMinLimit = styled.p`
  position: relative;
  left: 0;
`;

const SegmentMaxLimit = styled.p`
  position: relative;
  right: -0.125rem;
  transform: translateX(50%);

  &[data-lastsegmentlimit="true"] {
    right: 0;
    transform: none;
  }
`;

const Bar = styled.div`
  height: 6px;
  border-radius: 0.25rem;
  width: 100%;
  background-color: var(--Greys-Fog, #f4f4f4);
  position: relative;
  overflow: hidden;

  &[data-code="LL"],
  &[data-code="HH"] {
    background-color: var(--Primary-criticalRed, #ff7663);

    &::after {
      position: absolute;
      inset: 0;
      content: "";
      background: repeating-linear-gradient(
        -45deg,
        #fffcf3,
        #fffcf3 1.25px,
        rgba(255, 255, 255, 0) 0px,
        rgba(255, 255, 255, 0) 3.5px
      );
      opacity: 0.55;
    }
  }

  &[data-code="L"],
  &[data-code="H"] {
    background-color: var(--Primary-SunriseOrange, #ffbd70);
  }

  &[data-code="N"] {
    background-color: var(--Primary-InRange, #6fc696);
  }
`;

const Values = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Cursor = styled.div`
  display: flex;
  position: absolute;
  top: -9px;
  left: calc(var(--percentage-from-left, 0%) - 2px);
`;
//#endregion

interface GraySegmentProps {
  showCursor: boolean;
}

const GraySegment: FC<GraySegmentProps> = ({ showCursor }) => {
  return (
    <Segment style={{ "--width": "25%" } as React.CSSProperties}>
      <Bar />

      {/* If observation value is less than low limit, place cursor in middle of low range */}
      {showCursor && (
        <Cursor
          style={{ "--percentage-from-left": "50%" } as React.CSSProperties}
        >
          <IconLabValueCursor />
        </Cursor>
      )}
    </Segment>
  );
};

interface ReferenceRangeBarProps {
  observation: Observation;
}

const ReferenceRangeBar: FC<ReferenceRangeBarProps> = ({ observation }) => {
  const [
    { observationRangesData },
    { getRangeContainingObservation, getObservationRangesData }
  ] = useBloc(ObservationBundleBloc, {
    create: () => new ObservationBundleBloc(observation)
  });

  useEffect(() => {
    getObservationRangesData();
  }, []);

  const { observationValue, ranges, normalRangeMin, normalRangeMax } = useMemo(
    () => observationRangesData ?? {},
    [observationRangesData]
  );

  const rangeContainingObservationId = useMemo(
    () => getRangeContainingObservation(observation)?.id,
    []
  );

  if (!ranges?.length || typeof observationValue !== "number") {
    return null;
  }

  return (
    <Range>
      <Segments>
        {/* If there is only normal range available, and low limit is defined, add gray segment to beginning */}
        {ranges.length === 1 && typeof normalRangeMin === "number" && (
          <GraySegment
            showCursor={
              typeof observationValue === "number" &&
              observationValue < normalRangeMin
            }
          />
        )}

        {ranges.map((range, i) => {
          const {
            code,
            min,
            max,
            atLeastOneLimit,
            onlyOneLimit,
            widthPercentage,
            cursorPositionPercentageFromLeft
          } = range;

          return (
            <Segment
              key={`observation-range-${range.id}`}
              data-onlyonelimit={onlyOneLimit}
              style={
                {
                  "--width": `${widthPercentage}%`
                } as React.CSSProperties
              }
            >
              <Bar data-code={code} />

              {atLeastOneLimit && (
                <Values>
                  <SegmentMinLimit className="m0">
                    {/* Only display min limit for first range segment */}
                    {i === 0 && min}
                  </SegmentMinLimit>
                  <SegmentMaxLimit
                    className="m0"
                    data-lastsegmentlimit={i === ranges.length - 1}
                  >
                    {max}
                  </SegmentMaxLimit>
                </Values>
              )}

              {/* If observation value is contained in this range, calculate cursor position */}
              {range.id && range.id === rangeContainingObservationId && (
                <Cursor
                  style={
                    {
                      "--percentage-from-left": `${cursorPositionPercentageFromLeft}%`
                    } as React.CSSProperties
                  }
                >
                  <IconLabValueCursor />
                </Cursor>
              )}
            </Segment>
          );
        })}

        {/* If there is only normal range available, and high limit is defined, add gray segment to end */}
        {ranges.length === 1 && typeof normalRangeMax === "number" && (
          <GraySegment
            showCursor={
              typeof observationValue === "number" &&
              observationValue > normalRangeMax
            }
          />
        )}
      </Segments>
    </Range>
  );
};

export default ReferenceRangeBar;
