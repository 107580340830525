import styled from "@emotion/styled";
import clsx from "clsx";
import type { Observation } from "fhir/r4";
import type { FC, ReactElement } from "react";
import React, { useCallback, useMemo, useState } from "react";
import {
  IconCheckmarkRoundGreenBackground,
  IconChevronDownGray,
  IconExclamationRoundRedBackground,
  IconInfoRhombusYellowBackground,
  IconQuestionRoundGrayBackground
} from "src/constants/icons";
import ObservationBundleBloc from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker, useBloc } from "src/state/state";
import Translate from "src/ui/components/Translate/Translate";
import ReferenceRangeBar from "src/ui/components/UserLabResults/ReferenceRangeBar";

//#region Styled components
const Rows = styled.details`
  position: relative;
  padding: 0 1rem;
  overflow: hidden;
  max-height: 5rem;
  transition: max-height var(--ease-time-long) ease-in-out;

  &[open] {
    max-height: 50rem;
  }

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 1rem;
      right: 0;
      height: 1px;
      background-color: var(--color-gray-lighter, #e6e3db);
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 1.5rem;
  }
`;

const Row = styled.summary`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 0.25rem;
  padding: 1rem 0 0.875rem;

  &::-webkit-details-marker {
    display: none;
  }

  .dropdown-icon svg {
    transition: all var(--ease-time) var(--ease-type);
  }

  &.active {
    .dropdown-icon svg {
      transform: rotate(180deg);
    }

    .title {
      white-space: normal;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 1.5rem 0;
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 0.375rem;

  &:last-child {
    gap: 0.25rem;
    min-width: fit-content;
  }

  @media screen and (min-width: 768px) {
    gap: 0.625rem;

    &:last-child {
      gap: 0.75rem;
    }
  }
`;

const Title = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MoreContentRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0 1.25rem;

  @media screen and (min-width: 768px) {
    gap: 1.5rem;
    padding: 0 0 2rem;
  }
`;

const InfoMessage = styled.div`
  border-radius: 6px;
  padding: 0.75rem;
  background-color: var(--Greys-Fog, #f4f4f4);

  &[data-code="LL"],
  &[data-code="HH"] {
    background-color: var(--Secondary-fadedCritial, #ffd4c8);
  }

  &[data-code="L"],
  &[data-code="H"] {
    background-color: var(--Secondary-GoldenHourOrange, #ffdeb8);
  }

  &[data-code="N"] {
    background-color: var(--Secondary-AfternoonGreen, #c3f5d7);
  }
`;
//#endregion

const LabResultRow: FC<{ observation?: Observation }> = ({ observation }) => {
  if (!observation) {
    return null;
  }

  const [, { getRangeContainingObservation, getObservationData }] = useBloc(
    ObservationBundleBloc,
    {
      create: () => new ObservationBundleBloc()
    }
  );
  const [active, setActive] = useState(false);

  const {
    observationValueString,
    observationValue,
    observationUnit,
    observationTitle,
    isRangeDefined
  } = getObservationData(observation);

  const rangeContainingObservation = getRangeContainingObservation(observation);
  const codeOfRangeContainingObservation =
    rangeContainingObservation?.interpretationCode?.coding?.[0].code;

  const iconAndContextObject: { icon: ReactElement; context: string } =
    useMemo(() => {
      switch (codeOfRangeContainingObservation) {
        case "LL":
        case "HH":
          return {
            icon: <IconExclamationRoundRedBackground />,
            context: "outOfRange"
          };

        case "L":
        case "H":
          return {
            icon: <IconInfoRhombusYellowBackground />,
            context: "slightlyOutOfRange"
          };

        case "N":
          return {
            icon: <IconCheckmarkRoundGreenBackground />,
            context: "inRange"
          };

        default:
          if (!isRangeDefined && !observationValueString) {
            return {
              icon: <IconQuestionRoundGrayBackground />,
              context: "noRange"
            };
          }

          return {
            icon: <IconQuestionRoundGrayBackground />,
            context: "generic"
          };
      }
    }, [isRangeDefined, codeOfRangeContainingObservation]);

  const handleClick = useCallback(() => {
    setActive((prevState) => {
      if (!prevState) {
        tracker.track(TrackEvent.LabTestToggleOpened, {
          data: {
            Title: observationTitle,
            "Value and Unit": `${observationValue} ${observationUnit}`
          }
        });
      }

      return !prevState;
    });
  }, []);

  return (
    <Rows>
      <Row onClick={handleClick} className={clsx({ active })}>
        <Column>
          <IconWrapper>{iconAndContextObject.icon}</IconWrapper>
          <Title className="m0 strong title">{observationTitle}</Title>
        </Column>

        <Column>
          <p className="m0 color-c-80">
            {observationValue
              ? `${observationValue} ${observationUnit ?? ""}`
              : observationValueString}
          </p>
          <IconWrapper className="dropdown-icon">
            <IconChevronDownGray />
          </IconWrapper>
        </Column>
      </Row>

      <MoreContentRow className={clsx({ active })}>
        <ReferenceRangeBar observation={observation} />
        <InfoMessage data-code={codeOfRangeContainingObservation}>
          <small>
            <Translate
              msg="labResults.infoMessage"
              variables={{ context: iconAndContextObject.context }}
            />
          </small>
        </InfoMessage>
      </MoreContentRow>
    </Rows>
  );
};

export default LabResultRow;
